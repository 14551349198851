import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { auth } from "./firebase";

const Reset = ({closeModal}) => {
    const [reset, setReset] = useState(false);

    function resetPassword(e) {
        e.preventDefault()
        sendPasswordResetEmail(auth, document.querySelector("#email").value);
        console.log(document.querySelector("#email").value);
        setReset(true);
    }

    return (
        <div className="Reset">
            <a onClick={closeModal} className="material-symbols-outlined close-modal">close</a>
            <h2>Reset your password</h2>
            {!reset ? (
                <form className='reset-password' onSubmit={resetPassword}>
                    <label htmlFor="email">Registered Email</label>
                    <input type="email" name="email" id="email" />
                    <div className="modal-bottom">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            ) : (
                <div className="registration-message">
                    <p>Your request has been recieved. If you have an account with us, you should be recieving an email to your inbox or spam within 5 minutes.</p>
                </div>
            )}
        </div>
    );
}

export default Reset;