import { useState } from 'react';

import Register from './Register';
import SignIn from './SignIn';

import './Modal.css';
import Reset from './Reset';

const AuthModal = ({ closeModal }) => {
    const [modalState, setModalState] = useState(0);

    const handleModalStateChange = (newState) => {
        setModalState(newState);
    };

    return (
        <div className="AuthModal">
            { [<Register closeModal={closeModal} setModalState={handleModalStateChange} />, <SignIn closeModal={closeModal} setModalState={handleModalStateChange} />, <Reset closeModal={closeModal} />][modalState] }
        </div>
    );
}

export default AuthModal;