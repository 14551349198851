import { useState } from 'react';

import { auth } from './firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';

const Register = ({closeModal, setModalState}) => {
    function validateInfo(email, password, confPassword) {
        setEmailError("");
        setPasswordError("");
        setConfPasswordError("");

        const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i);
        const containsUpperAndLowercase = new RegExp(/(?=.*[a-z])(?=.*[A-Z])/);
        const containsSpecialChars = new RegExp(/(?=.*\d)(?=.*[-+_!@#$%^&*.,?])/);

        if (!emailRegex.test(email)) {
            setEmailError("This is not a valid Email");
            return false;
        } else if (password.length < 8) {
            setPasswordError("Password must be a minimum of 8 characters");
            return false;
        } else if (!containsUpperAndLowercase.test(password)) {
            setPasswordError("Password must contain a mix of uppercase and lowercase characters");
            return false;
        } else if (!containsSpecialChars.test(password)) {
            setPasswordError("Password must contain at least one digit and special character");
            return false;
        } else if (password !== confPassword) {
            setConfPasswordError("Password and confirmation password are not equal");
            return false;
        }

        return true;
    }

    function registerUser(e) {
        e.preventDefault();
        const [email, password, confPassword] = Array.from(document.querySelectorAll('.registration input')).map(input => input.value);

        if (!validateInfo(email, password, confPassword)) {
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            sendEmailVerification(userCredential.user);
            setRegistered(true);
        })
        .catch((error) => {
            signInWithEmailAndPassword(auth, email, password)
            .then((user) => {
                console.log(user.emailVerified)
                if (user.user.emailVerified) {
                    setEmailError("This email has already been verified");
                } else {
                    sendEmailVerification(user.user);
                    setRegistered(true);
                }
            })
        })
    }
    
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confPasswordError, setConfPasswordError] = useState("");

    const [passwordType, setPasswordType] = useState("password");
    const [confPasswordType, setConfPasswordType] = useState("password");

    const [registered, setRegistered] = useState(false);

    return (
        <div className="Register">
            <h2>Get Started</h2>
            <a onClick={closeModal} className="material-symbols-outlined close-modal">close</a>

            {!registered ? (
                <form className='registration' onSubmit={registerUser}>
                    
                <label htmlFor="DIA_Email">School Email</label>
                <input type="email" name='DIA_Email' required />
                <p className="error">{emailError}</p>

                <label htmlFor="password">Password</label>
                <div className="password-container">
                    <input type={passwordType} name='password' required minLength={8} />
                    <a onClick={() => setPasswordType(passwordType == "password" ? "text" : "password")} className="material-symbols-outlined password-visibility">
                        {passwordType == "password" ? "visibility" : "visibility_off"}
                    </a>
                </div>
                <p className="error">{passwordError}</p>

                <label htmlFor="confirm_password">Confirm password</label>
                <div className="password-container">
                    <input type={confPasswordType} name='password' required minLength={8} />
                    <a onClick={() => setConfPasswordType(confPasswordType == "password" ? "text" : "password")} className="material-symbols-outlined password-visibility">
                        {confPasswordType == "password" ? "visibility" : "visibility_off"}
                    </a>
                </div>
                <p className="error">{confPasswordError}</p>

                <div className="modal-bottom">
                    <button type="submit">Next step</button>
                    <p className='edge-case'>Already have an account? <a onClick={() => setModalState(1)}>Sign in</a></p>
                    <p className='edge-case'>Experiencing difficulties? <a href="https://www.instagram.com/diapairconnect/">Contact us</a></p>
                </div>
                </form>
            ) : (
                <div className="registration-message">
                    <h3>Thanks for registering!</h3>
                    <p>Please check your inbox and spam folder for a confirmation link within 5 minutes, to confirm your account. If you don't recieve a link after 5 minutes, try registering again.</p>
                </div>
            )}
        </div>
    );
}

export default Register;