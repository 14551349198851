import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import AuthModal from '../auth/AuthModal';

import Hafid from './team/hafid.jpg';
import Shaunak from './team/shaunak.jpg';
import Ruhaan from './team/ruhaan.jpg';

import './Landing.css';
function Landing() {
  const [scrollTop, setScrollTop] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className="Landing">
      <div className="hero">
        <img src="https://ik.imagekit.io/hafide/assets/blobLeft.webp" alt="Blob" className="blob left" />
        <img src="https://ik.imagekit.io/hafide/assets/blobRight.webp" alt="Blob" className="blob right" />
        <img src="https://ik.imagekit.io/hafide/assets/blobTop.webp" alt="Blob" className="blob top" />
        <h1>DIAPAIR</h1>
        <h2>Bridging the Gap Between <span className="gradient">Potential</span> and <span className="gradient">Experience</span></h2>
        <button type="submit" onClick={openModal}>Register <span className="material-symbols-outlined">arrow_forward_ios</span></button>
      </div>

      <div id="carousel" style={{"--scroll-position": scrollTop}}>
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/1.webp" alt="Carousel image" className="carousel-img" style={{"--index": 1}} />
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/2.webp" alt="Carousel image" className="carousel-img" style={{"--index": 2}} />
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/3.webp" alt="Carousel image" className="carousel-img" style={{"--index": 3}} />
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/4.webp" alt="Carousel image" className="carousel-img" style={{"--index": 4}} />
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/5.webp" alt="Carousel image" className="carousel-img" style={{"--index": 5}} />
        <img src="https://ik.imagekit.io/hafide/assets/carousel_images/6.webp" alt="Carousel image" className="carousel-img" style={{"--index": 6}} />
      </div>

      <div id="testimonial">
        <h2 className="name">Rachana Sippy</h2>
        <h3 className="description">Career Coach and College Counsellor</h3>
        <p className="content">This is a great platform for the current students to use the resources and learn from the alumni their journey, 
        experiences and how to navigate. Well done to the DIAPAIR team for creating this fantastic opportunity for the DIAEH students. </p>
      </div>

      <div className="block">
        <h2>Why DIAPAIR?</h2>
        <p>Joining DIAPAIR offers students a unique opportunity to grow intellectually, connect with their school community, and gain valuable insights. This platform pairs students with accomplished alumni, fostering knowledge exchange. Engaging with graduates helps younger individuals make informed academic and career choices. DIAPAIR promotes unity, collaboration, and mentorship within the school community. It enables students to access valuable experience, broaden horizons, and build lifelong relationships, enhancing their academic and personal growth.</p>
      </div>

      <div className="block">
        <h2>Meet the Team</h2>
        <div className="team-images">
          <div>
            <img src={Ruhaan} alt="Ruhaan" />
            <p>Ruhaan Malhotra</p>
            <p className='description'>Co-founder</p>
          </div>
          <div>
            <img src={Hafid} alt="Hafid" />
            <p>Hafid Eledath</p>
            <p className='description'>Head of Tech</p>
          </div>
          <div>
            <img src={Shaunak} alt="Shaunak" />
            <p>Shaunak Agarwal</p>
            <p className='description'>Head of Finance / co-president</p>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        className="Modal">
          <AuthModal closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default Landing;