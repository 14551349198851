import { useState } from 'react';
import { firestore } from '../auth/firebase';

import Modal from 'react-modal';

import '../auth/Modal.css';

Modal.setAppElement('#root');

const defaultUserInfo = {
  name: "",
  contact: "",
  classOf: new Date().getFullYear(),
  bio: "",
  tags: ["", "", ""],
  pairedWith: null
}

const ProfileModal = ({modalIsOpen, closeModal, user, userInfo}) => {
  let closeButtonExists = true;
  if (!userInfo) {
    closeButtonExists = false;
    userInfo = defaultUserInfo;
  }

  function validateInfo(name, contact, classOf, bio, tags) {
    setNameError("");
    setContactError("");
    setClassOfError("");
    setBioError("");
    setTagsError("");

    const nameRegex = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/i);
    const maxClassOf = currentYear + (13 - 8);
    const bioLength = bio.length;

    if (!nameRegex.test(name) | name.split(" ").length !== 2) {
      setNameError("This is not a valid name.");
      return false;
    } else if (!contact) {
      setContactError("You must provide a contact email or number.")
    } else if (classOf > maxClassOf | classOf < 2008) { // From first DIA alumni to current year 8s
      setClassOfError(`You must be between the class of 2008 and ${maxClassOf}`);
      return false;
    } else if (bioLength < 5 | bioLength > 50) {
      setBioError("Your bio must be between 5 and 50 characters.");
      return false;
    } else if (new Set(tags).size !== 3) {
      setTagsError("Your interests must be unique.");
      return false;
    } else if (tags.filter((tag => tag.length >= 1 && tag.length <= 11)).length !== 3) {
      setTagsError("Your interests must be between 1 and 19 characters long.");
      return false;
    }

    return true;
}

async function setProfile(e) {
    e.preventDefault();
    const [name, contact, classOf, bio, ...tags] = Array.from(document.querySelectorAll('.profile-setup input')).map(input => input.value.trim());

    if (!validateInfo(name, contact, classOf, bio, tags.map(tag => tag.toLowerCase()))) {
        return;
    }

    const userRef = firestore.doc(`users/${user.uid}`);

    let pairedWith;
    await userRef.get()
    .then((docSnapshot) => {
      if (docSnapshot.exists) {
        pairedWith = docSnapshot.data().pairedWith;
      } else {
        pairedWith = null;
      }
    });

    await userRef.set({
        name: name,
        contact: contact,
        classOf: classOf,
        bio: bio,
        tags: tags,
        pairedWith: pairedWith
    });

    window.location.reload();
}

  const [nameError, setNameError] = useState(" ")
  const [contactError, setContactError] = useState(" ")
  const [classOfError, setClassOfError] = useState(" ");
  const [bioError, setBioError] = useState(" ");
  const [tagsError, setTagsError] = useState(" ");

  const currentYear = new Date().getFullYear();

  return (
    <div className="ProfileModal">
        <Modal isOpen={modalIsOpen} className="Modal">
        { closeButtonExists ? (<a onClick={closeModal} className="material-symbols-outlined close-modal">close</a>) : null }
          <h2>Set up your profile</h2>

          <form className='profile-setup' onSubmit={setProfile}>
            <label htmlFor="fullName">First and last name</label>
            <input type='text' name='fullName' minLength={5} defaultValue={userInfo.name} required />
            <p className="error">{nameError}</p>

            <label htmlFor="fullName">Contact</label>
            <input type='text' name='fullName' defaultValue={userInfo.contact} required />
            <p className="error">{contactError}</p>

            <label htmlFor="classOf">Graduating class</label>
            <input type='number' name='classOf' min={2008} max={currentYear + 5} defaultValue={userInfo.classOf} required />
            <p className="error">{classOfError}</p>

            <label htmlFor="bio">Describe yourself in 50 characters</label>
            <input type="text" name="bio" minLength={5} maxLength={50} defaultValue={userInfo.bio} required />
            <p className="error">{bioError}</p>

            <label htmlFor="tags">Three interests of yours</label>
            <div className="tag-container">  
              <input type="text" name="tag" maxLength={11} defaultValue={userInfo.tags[0]} required />
              <input type="text" name="tag" maxLength={11} defaultValue={userInfo.tags[1]} required />
              <input type="text" name="tag" maxLength={11} defaultValue={userInfo.tags[2]} required />
            </div>
            <p className="error">{tagsError}</p>
            <div className="modal-bottom"><button type="submit">Submit</button></div>
          </form>
        </Modal>
    </div>
  );
}

export default ProfileModal;