import { useState, useEffect, useRef } from 'react';


import Logo from '../assets/logoDashboard.svg';
import Profile from '../assets/profile.svg'

import './Nav.css';


const Nav = ({ user, signOut, toggleDarkMode, openProfileSetup }) => {
    const [dropdownState, setDropdownState] = useState("hidden")

    function toggleDropdown() {
        setDropdownState(dropdownState === "hidden" ? "show" : "hidden");
    }

    const dashboardRef = useRef();

    useEffect(() => {
        let handler = (event) => {
            if (!dashboardRef.current.contains(event.target)) {
                setDropdownState("hidden");
            }
        };

        document.addEventListener("mousedown", handler);
    });

    useEffect(() => {
        document.querySelector("a[href='" + document.location.pathname + "']").classList.add("active");
    }, [ ])

    return (
        <div className="Nav">
            <nav>
                <img src={Logo} alt="logo" className='logo' />
                <div className="nav-links">
                    <a href='/'>Dashboard</a>
                    <a href='/chat'>Chat</a>
                </div>
                <div className="user">
                    <div ref={dashboardRef}>
                        <img src={Profile} alt="profile" onClick={toggleDropdown} />
                        <div className={"dropdown " + dropdownState}>
                            <a onClick={openProfileSetup}>Profile</a>
                            <a onClick={toggleDarkMode}>Toggle Dark Mode</a>
                            <a onClick={signOut}>Sign Out</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Nav;