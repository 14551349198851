import { useState } from 'react';

import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignIn = ({ closeModal, setModalState }) => {
    function signInUser(e) {
        e.preventDefault();
        const [email, password] = Array.from(document.querySelectorAll('.sign-in-user input')).map(input => input.value);

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            })
            .catch((error) => {
                setEmailError("Invalid email or password.");
            })
    }

    const [emailError, setEmailError] = useState("");

    const [passwordType, setPasswordType] = useState("password");

    return (
        <div className="Sign-in">
            <h2>Sign in</h2>
            <a onClick={closeModal} className="material-symbols-outlined close-modal">close</a>

            <form className='sign-in-user' onSubmit={signInUser}>

            <label htmlFor="DIA_Email">School Email</label>
            <input type="email" name='DIA_Email' required />
            <p className="error">{emailError}</p>

            <label htmlFor="password">Password</label>
            <div className="password-container">
                <input type={passwordType} name='password' required minLength={8} />
                <a onClick={() => setPasswordType(passwordType == "password" ? "text" : "password")} className="material-symbols-outlined password-visibility">
                    {passwordType == "password" ? "visibility" : "visibility_off"}
                </a>
            </div>

            <div className="modal-bottom">
                <button type="submit">Next step</button>
                <p className='edge-case'>Don't have an account? <a onClick={() => setModalState(0)}>Register</a></p>
                <p className='edge-case'>Forgot your password? <a onClick={() => setModalState(2)}>Reset it</a></p>
                </div>
            </form>
        </div>
    );
}

export default SignIn;