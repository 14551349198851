import React, { useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore, auth } from '../auth/firebase';
import socketIOClient from 'socket.io-client';

import './Chat.css'

const defaultUserInfo = {
  name: "loading...",
  email: "loading...",
  classOf: "...",
  bio: "loading...",
  tags: [],
  pairedWith: null
}

const Chat = ({ userInfo=defaultUserInfo, roomId="" }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('timestamp').limitToLast(50);
  const [chatMessages] = useCollectionData(query, { idField: 'id' });

  const socket = socketIOClient('localhost:3000');

  useEffect(() => {
    if (chatMessages) {
      const roomMessages = chatMessages.filter(message => message.roomId === roomId);
      setMessages(roomMessages);
    }
  }, [chatMessages]);

  const sendMessage = async () => {
    if (newMessage) {
      await messagesRef.add({
        roomId: roomId,
        text: newMessage,
        timestamp: new Date(),
        userId: auth.currentUser.uid,
        userName: userInfo.name,
      });

      socket.emit('message', { text: newMessage });
    }
  };

  return (
    <div className="Chat">
      <div className="message-container">
        <div>
          {messages &&
            messages.map((message) => (
              <div key={message.id} className={`message ${message.userId === auth.currentUser.uid ? 'sent' : 'received'}`}>
                <strong>{message.userName}</strong>: {message.text}
              </div>
            ))}
        </div>
        <div className="message-input">
          <input
            type="text"
            id="chat-box"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setNewMessage('');
                sendMessage();
              };
            }
          }
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
