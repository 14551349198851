import { useEffect, useState } from 'react';
import { firestore } from '../auth/firebase';

import './Dashboard.css';

const defaultUserInfo = {
    name: "loading...",
    email: "loading...",
    classOf: "...",
	bio: "loading...",
	tags: [],
    pairedWith: null
}

const Dashboard = ({ userInfo=defaultUserInfo }) => {
    const [pairedWithInfo, setPairedWithInfo] = useState(null);

    useEffect(() => {
        if (userInfo.pairedWith !== null) {
            const pairedWithRef = firestore.doc(`users/${userInfo.pairedWith}`);
    
            pairedWithRef.get()
            .then((docSnapshot) => {
                setPairedWithInfo(docSnapshot.data());
            })
        }
    }, []);

    return (
        <div className="Dashboard">
            <h2>You</h2>

            <div className='bento-row user'>
                <div className='user-info'>
                    <h4 className='name'>{userInfo.name}</h4>
                    <h5 className='contact'>{userInfo.contact}</h5>
                    <h4 className='class-of'>Class of {userInfo.classOf}</h4>
                </div>
                <div className='user-description'>
                    <p className='bio'>{userInfo.bio}</p>
                    <div className='tags'>
                        {userInfo.tags.map(tag => <div className="tag">{tag}</div>)}
                    </div>
                </div>
            </div>

            <h2>Your Partner</h2>

            { pairedWithInfo !== null ? (
                <div className='bento-row partner'>
                    <div  className='user-description'>
                        <p className='bio'>{pairedWithInfo.bio}</p>
                        <div className='tags'>
                        {pairedWithInfo.tags.map(tag => <div className="tag">{tag}</div>)}
                        </div>
                    </div>
                    <div className='user-info'>
                        <h4 className='name'>{pairedWithInfo.name}</h4>
                        <h5 className="contact">{pairedWithInfo.contact}</h5>
                        <h4 className='class-of'>Class of {pairedWithInfo.classOf}</h4>
                    </div>
                </div>
            ) : (userInfo.pairedWith !== null ? (
                <p>Loading...</p>
            ) : (
                <p>Either you are experiencing latency, or you are not yet paired with anyone. Please wait a few days for the pairing process to complete.</p>
            ))}
        </div>
    );
}

export default Dashboard;