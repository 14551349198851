import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const app = firebase.initializeApp({
  apiKey: "AIzaSyDpETp0HlzsGkQcLgTyUFfPkbBdaR5zyRY",
  authDomain: "diapair-demo.firebaseapp.com",
  projectId: "diapair-demo",
  storageBucket: "diapair-demo.appspot.com",
  messagingSenderId: "1074670504402",
  appId: "1:1074670504402:web:92c99aaf0fbd75e38fe00b",
  measurementId: "G-55RS492BRM"
});

export const auth = firebase.auth();
export const firestore = app.firestore();
export const signOut = auth.signOut;