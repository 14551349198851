import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Landing from './landing/Landing';
import Nav from './registered/Nav';
import Dashboard from './registered/Dashboard';
import Chat from './registered/Chat';
import ProfileModal from './registered/ProfileModal';

import { auth, firestore } from './auth/firebase'

import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div className='loading'>loading</div>;
  }

  return (
    user?.emailVerified ? <ServeRegistered user={user} /> : <ServeLanding />
  );
}

function ServeLanding() {
  return (
    <div className="App">
      <Landing />
    </div>
  );
};

function ServeRegistered({ user }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [roomId, setRoomId] = useState();

  useEffect(() => {
    const userRef = firestore.doc(`users/${user.uid}`);

    userRef.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        setModalIsOpen(true);
      } else {
        setModalIsOpen(false);
        setUserInfo(docSnapshot.data());
        setRoomId([user.uid, docSnapshot.data().pairedWith].sort().join(""));
      }
    });
  }, [user.uid]);

  const [colorMode, setColorMode] = useState((getPrevColorMode() !== null) ? getPrevColorMode() : "light");

    useEffect(() => {
        window.localStorage.setItem('colorMode', colorMode);
    }, [colorMode]);

    function toggleDarkMode() {
        setColorMode(colorMode === "light" ? "dark" : "light");
    }

    function getPrevColorMode() {
        return window.localStorage.getItem('colorMode');
    }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className={"RegisteredUser " + colorMode}>
        { userInfo ? (<Nav user={user} signOut={() => signOut(auth)} toggleDarkMode={toggleDarkMode} openProfileSetup={() => setModalIsOpen(true)} />) : null}
        <Routes>
          <Route path="/" Component={() => <Dashboard userInfo={userInfo} />} />
          {userInfo?.pairedWith ? (
            <Route path="/chat" element={<Chat userInfo={userInfo} roomId={roomId} />} />
          ) : (
            <Route path="/chat" element={<div className='Chat'><h2>You are not currently yet paired with anyone or you are experiencing latency.</h2></div>} />
          )}
        </Routes>
        <ProfileModal modalIsOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)} user={user} userInfo={userInfo} />
      </div>
    </BrowserRouter>
  );
}

export default App;